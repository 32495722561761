import React from 'react';

const IntroContent = () => <div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img className='content-img-frame' src="https://apple.o2o.kr/img2/camel_chatbot/2068.svg" alt="이미지 1" />
            <img className='content-img-frame' src="https://apple.o2o.kr/img2/camel_chatbot/2078.svg" alt="이미지 1" />
            <img className='content-img-frame' src="https://apple.o2o.kr/img2/camel_chatbot/2069.svg" alt="이미지 1" />
            <img className='content-img-frame' src="https://apple.o2o.kr/img2/camel_chatbot/2070.svg" alt="이미지 1" />
            <img className='content-img-frame' src="https://apple.o2o.kr/img2/camel_chatbot/2071.svg" alt="이미지 1" />
            <img className='content-img-frame' src="https://apple.o2o.kr/img2/camel_chatbot/2072.svg" alt="이미지 1" />
            <img className='content-img-frame' src="https://apple.o2o.kr/img2/camel_chatbot/2073.svg" alt="이미지 1" />
            <img className='content-img-frame' src="https://apple.o2o.kr/img2/camel_chatbot/2074.svg" alt="이미지 1" />
            <img className='content-img-frame' src="https://apple.o2o.kr/img2/camel_chatbot/2075.svg" alt="이미지 1" />
            <img className='content-img-frame' src="https://apple.o2o.kr/img2/camel_chatbot/2076.svg" alt="이미지 1" />
        </div>
    </div>;

export default IntroContent;
