import React from 'react';

const ServiceContent = () => 
    <div className="container mx-auto my-10 p-8 border border-gray-200 rounded-lg shadow-lg bg-white" style={{maxWidth:'100%'}}>

    {/* 1. RAG 챗봇 서비스(url 제공) */}
    <div className="flex w-full justify-center items-center" style={{marginTop:'50px'}}>
        <div className="flex-1 flex justify-end">
        {/* 여기에 이미지를 삽입하세요. 예시로 임시 이미지를 사용합니다. */}
            <img src="https://apple.o2o.kr/img2/camel_chatbot/service-category-url.svg" alt="Service Description" className="w-48 h-auto" style={{marginRight : '50px'}}/>
        </div>
        
        <div className="flex-1 p-4">
            {/* 여기에 설명을 삽입하세요. */}
            <p>url 제공형</p>
            <h2 className="text-xl font-bold mb-2">RAG 챗봇 서비스(url 제공)</h2>
            <p>대상 : url 형태로 제공되는 챗봇으로 고객 상담이 필요한 다양한 기관, 기업 대상</p>
            <p>용도 : 정보제공, KMS</p>
            <p>요금 : 구독형, 납품 + 연간 관리 비용</p>
        </div>
    </div>

    {/* 2. RAG 챗봇 서비스(내부설치형) */}
    <div className="flex w-full justify-center items-center" style={{marginTop:'50px'}}>
        <div className="flex-1 flex justify-end">
        {/* 여기에 이미지를 삽입하세요. 예시로 임시 이미지를 사용합니다. */}
            <img src="https://apple.o2o.kr/img2/camel_chatbot/service-category-inside.svg" alt="Service Description" className="w-48 h-auto" style={{marginRight : '50px'}}/>
        </div>
        
        <div className="flex-1 p-4">
            {/* 여기에 설명을 삽입하세요. */}
            <p>내부 설치형</p>
            <h2 className="text-xl font-bold mb-2">RAG 챗봇 서비스(내부 설치형)</h2>
            <p>대상 : 중견이상기업, 공공기관, 지자체 중 데이터 보안에 민감한곳</p>
            <p>용도 : 정보제공, KMS</p>
            <p>요금 : 납품 + 연간 관리 비용</p>
        </div>
    </div>

    {/* 3. 벡터 기반 검색 엔진 */}
    <div className="flex w-full justify-center items-center" style={{marginTop:'50px'}}>
        <div className="flex-1 flex justify-end">
        {/* 여기에 이미지를 삽입하세요. 예시로 임시 이미지를 사용합니다. */}
            <img src="https://apple.o2o.kr/img2/camel_chatbot/service-category-engine.svg" alt="Service Description" className="w-48 h-auto" style={{marginRight : '50px'}}/>
        </div>
        
        <div className="flex-1 p-4">
            {/* 여기에 설명을 삽입하세요. */}
            <p>검색 엔진</p>
            <h2 className="text-xl font-bold mb-2">벡터 기반 검색 엔진</h2>
            <p>대상 : 관공서, 대기업(은행, 보험, 금융권)</p>
            <p>용도 : 검색 엔진, KMS</p>
            <p>요금 : 납품 + 연간 관리 비용</p>
        </div>
    </div>

    {/* 4. 구독형 서비스 */}
    <div className="flex w-full justify-center items-center" style={{marginTop:'50px'}}>
        <div className="flex-1 flex justify-end">
        {/* 여기에 이미지를 삽입하세요. 예시로 임시 이미지를 사용합니다. */}
            <img src="https://apple.o2o.kr/img2/camel_chatbot/service-category-personal.svg" alt="Service Description" className="w-48 h-auto" style={{marginRight : '50px'}}/>
        </div>
        
        <div className="flex-1 p-4">
            {/* 여기에 설명을 삽입하세요. */}
            <p>개인형 서비스</p>
            <h2 className="text-xl font-bold mb-2">구독형 서비스</h2>
            <p>대상 : 개인, 소상공인, 소기업 등</p>
            <p>용도 : 정보제공</p>
            <p>요금 : 구독형, 무료 서비스 제공(일일 사용량 제한)</p>
        </div>
    </div>

    
    </div>;

export default ServiceContent;


