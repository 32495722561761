import React, { useState } from 'react';

const ContactContent = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [inquiry, setInquiry] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        // 이 부분에서 실제 이메일을 발송하는 API를 호출합니다.
        // 예시는 API 호출 코드를 포함하지 않고 있습니다.

        console.log('Sending email with the following details:');
        console.log(`Name: ${name}`);
        console.log(`Email: ${email}`);
        console.log(`Inquiry: ${inquiry}`);
        
        // API 호출 예시:
        // await fetch('YOUR_BACKEND_API_ENDPOINT', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({ name, email, inquiry }),
        // });

        // 폼을 제출한 후 입력을 초기화할 수 있습니다.
        setName('');
        setEmail('');
        setInquiry('');
    };

    return (
        
        <div className="container mx-auto my-10 p-8 border border-gray-200 rounded-lg shadow-lg bg-white" style={{maxWidth:'100%'}}>
        
        <h1 style={{fontSize:'36px', fontWeight:'900'}}>고객상담</h1>
        
        <div className="flex flex-row items-start space-x-4"> {/* Flex 컨테이너 설정 */}
                <div className="flex-initial"> {/* 이미지를 위한 div */}
                    <img 
                        src="https://apple.o2o.kr/img2/camel_chatbot/contact-img.png" 
                        alt="Placeholder" 
                        className="w-96 h-auto" 
                    />
                </div>
                <div className="flex-1"> {/* 폼을 위한 div */}
        
        
        <form onSubmit={handleSubmit} className="space-y-4">
            <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">이름</label>
            <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="이름을 입력하세요"
                required
            />
            </div>
            <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">고객님 이메일</label>
            <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="고객님의 이메일을 입력하세요"
                required
            />
            </div>
            <div>
            <label htmlFor="inquiry" className="block text-sm font-medium text-gray-700">문의 내용</label>
            <textarea
                id="inquiry"
                value={inquiry}
                onChange={(e) => setInquiry(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="문의 내용을 입력하세요"
                required
            />
            </div>
            <div className="flex justify-end">
            <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                문의하기
            </button>
            </div>
        </form>
        </div>
    </div>
</div>
    );
    };

export default ContactContent;
