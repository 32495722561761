import React from 'react';

const Footer = () => (
    <div className="container mx-auto my-10 border-gray-200 rounded-lg shadow-lg bg-white" style={{Width:'80%', maxWidth:'60%'}}>
    <footer className="bg-gray-800 text-white text-center p-4 w-full">
        <div className='text-left'>
            <p>(주)오투오</p>
            <p>대표이사 안성민</p>
            <p>사업자등록번호 620-87-00810</p>
            <p>info@o2o.kr</p>
            <p>서울특별시 서초구 매헌로8길 47, AI 양재 허브(희경빌딩 B동) 203호 (06770)</p>
        </div>
    </footer>
    </div>
    
);

export default Footer;
